import { Grid, ResponsiveText } from '@eventbrite/wagtail-components';
import React from 'react';
import { useTheme } from '../../../utils/context/theme-context';
import CTABlock, {
    CTABlockInterface,
} from '../../sharedComponents/cta-block/CTABlock';
import FeatureBlock, {
    FeatureBlockInterface,
} from '../../sharedComponents/feature-block/FeatureBlock';
import LazyloadImage from '../../sharedComponents/LazyloadImage';
import { BaseModuleProps } from '../types';
import { convertContentfulVariantToEnum } from '../utils';
import './productHighlight.scss';

export interface ImageBlock {
    imageUrl: string;
    altText: string;
    alignment?: 'left' | 'right';
}

export interface ProductHighlightProps {
    headline?: string;
    subheader?: string;
    ctaBlock: CTABlockInterface;
    featureBlocks: FeatureBlockInterface[];
    moduleClass?: String;
    image: ImageBlock;
    alignment?: 'left' | 'right';
    isContentFromContentful?: boolean;
}

const ProductHighlight: React.FC<ProductHighlightProps & BaseModuleProps> = ({
    headline,
    subheader,
    featureBlocks,
    ctaBlock,
    moduleClass,
    image,
    variant,
    alignment,
    isContentFromContentful,
}) => {
    let imageAlignment = image.alignment;
    if (isContentFromContentful) {
        imageAlignment = alignment ? alignment : 'left';
        variant = convertContentfulVariantToEnum(variant);
    }
    const baseOptions = { variant };
    const theme = useTheme();
    const [backgroundClass] = theme.generateStyles('background', baseOptions);
    const [textClass] = theme.generateStyles('text', baseOptions);
    const shouldIncludeCtaButton = ctaBlock?.url && ctaBlock?.callToAction;
    return (
        <section className={`${backgroundClass} ${moduleClass || ''}`}>
            <div className="product-highlight module-landing-page__module-container-content">
                {headline && (
                    <header>
                        <ResponsiveText
                            base={{
                                fontSize: 52,
                                lineHeight: 64,
                            }}
                            small={{
                                fontSize: 32,
                                lineHeight: 40,
                            }}
                            component="h2"
                            className={`product-highlight__headline ${textClass}`}
                        >
                            {headline}
                        </ResponsiveText>
                    </header>
                )}
                <ResponsiveText
                    base={{
                        fontSize: 24,
                        lineHeight: 32,
                    }}
                    small={{
                        fontSize: 18,
                        lineHeight: 24,
                    }}
                    className={`product-highlight__sub-header ${textClass}`}
                >
                    {subheader}
                </ResponsiveText>
                <div
                    className={`product-highlight__content ${
                        imageAlignment !== 'left' &&
                        'product-highlight__content-right'
                    }`}
                >
                    <LazyloadImage
                        classes="product-highlight__content__image"
                        image={image.imageUrl}
                        alt={image.altText}
                    />
                    <Grid
                        base={{
                            columnCount: featureBlocks.length < 4 ? 1 : 2,
                            gap: 24,
                            rowGap: 40,
                        }}
                        small={{
                            columnCount: 1,
                            gap: 28,
                        }}
                        className="product-highlight__content__feature-blocks"
                    >
                        {featureBlocks.map((blockProps, idx) => (
                            <FeatureBlock
                                key={idx}
                                {...blockProps}
                                variant={variant}
                            />
                        ))}
                    </Grid>
                </div>
                {shouldIncludeCtaButton && (
                    <div className="product-highlight__cta-blocks">
                        <CTABlock
                            {...{
                                ...ctaBlock,
                                size: 'regular',
                                variant: 'button',
                                themeVariant: variant,
                            }}
                        />
                    </div>
                )}
            </div>
        </section>
    );
};

export default ProductHighlight;
